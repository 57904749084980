import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    //CSRFトークン取得(DOMContentLoadedイベント発生前にstimulusのコントローラはconnectされる可能性があるため普通に取得)
    this.token = document.querySelector('meta[name="csrf-token"]').content;
    window.formSharedParams = null;
  }
  submit(e) {
    //フォーム取得
    let form = this.element;
    //フォームの送信データを取得
    let formData = new FormData(form);
    //クエリ文字列を構築
    let queryString = new URLSearchParams(formData).toString();
    
    //別のフォームでのアクション実行時、もしくは前のアクション実行時のクエリパラメータが存在していればくっつける
    if(window.formSharedParams){
      queryString += "&";
      queryString += window.formSharedParams;
    }
    window.formSharedParams = queryString;

    //検索内容を表示するタグのUI要素の文字列を作成
    let ulElement = this.genQueryBadgeEl(queryString);

    //リクエスト用URL生成
    let url = form.action + `?${queryString}`;
    let method = form.method;

    //リクエスト送信
    fetch(url, {
      'X-CSRF-Token': this.token,
      method: method
    })
    .then(response => response.text())
    .then(html =>{
      let parser = new DOMParser();
      //更新したい箇所のみを取得
      let frame = document.getElementById('product__wrap');
      //レスポンスのHTMLをパース
      let doc = parser.parseFromString(html, 'text/html');
      //更新したい箇所のみを取得
      let newFrame = doc.getElementById('product__wrap');
      //前の要素を削除
      frame.innerHTML = '';
      //新しい要素を挿入
      //挿入する要素が見つからなかった時エラーにならないように条件分岐
      if(newFrame){
        frame.insertAdjacentHTML('afterbegin', newFrame.outerHTML);
      }else{
        frame.insertAdjacentHTML('afterbegin', '');
      }
      //検索内容を表示するバッジを挿入
      let parentUl = document.getElementById("serach_lists");
      parentUl.innerHTML = ulElement;

      //全て消すイベント登録
      let badgeDeleteAllElement = document.getElementById('delete_all_badges');
      if(badgeDeleteAllElement){
        badgeDeleteAllElement.addEventListener('click', this.redirectCurrentURL);
      }
    })
  }

  //クエリパラメータを除いたURLでページをリロード
  redirectCurrentURL(){
    let currentURL = window.location.href;
    //クエリパラメータを取り除く
    let cleanURL = currentURL.split('?')[0];
    window.location.href = cleanURL;
  }

  //絞り込み中の検索内容のバッジを生成
  genQueryBadgeEl(queryString){
    let adjustQueryParams = this.getAdjustQueryParams(queryString);
    return this.createBadgeElement(adjustQueryParams);
  }

  //検索時のクエリパラメータをキーバリューの形で扱いやすく調整する
  getAdjustQueryParams(queryString){
    // クエリパラメータをキーバリューの形にしたオブジェクトを格納
    let adjustQueryParams = {};
    // 削除対象の文字列
    let toRemove = ["[]", "[g]", "[0]", "[m]", "=or", "=and"];
    // 削除対象の文字列を正規表現に変換
    let regexPattern = toRemove.map((str)=>{
      return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    }).join('|');
    // 正規表現を使って文字列を削除
    queryString = decodeURIComponent(queryString).replace(new RegExp(regexPattern, 'g'), '');
    // &で絞り込み内容ごとに分割
    queryString = queryString.split("&");
    // 各ペアを処理
    queryString.forEach((pair)=>{
      // "="でキーと値に分割
      let parts = pair.split("=");
      // "q["と"]"を取り除いてキーを作成
      let key = decodeURIComponent(parts[0].replace("q[", "").replace("]", ""));
      // key が "q" とhiddenのクエリ("apparel_disclousure_eq")の場合はスキップ
      if (key === "q" || key === "apparel_disclousure_eq") {
        return;
      }
      // 値を取り出す
      let value = decodeURIComponent(parts[1]);
      
      adjustQueryParams[`${key}_${value}`] = value;
    });
    return adjustQueryParams;
  }

  // バッジの要素の作成
  createBadgeElement(adjustQueryParams){
    let badges = '<div class="select-tag__wrap"><ul class="select-tag__items" data-controller = "supplierSearch">';
    let labelEl = "";
    let labelText = "";
    let noCreateBadgeFieldFlg = false;
    let noCreateBadgeFlg = false;
    let checkDuplicateString = "";
    let duplicateTagFlg = false;
    let allDelete = "";

    //ロケール取得
    let urlLocal = window.location.href.split('/')[3];
    let browsLocale = navigator.language;

    // ループでキーから要素を作成
    Object.entries(adjustQueryParams).forEach(([key, value])=>{
      //ロケールが日本以外の場合
      if(urlLocal != "suppliers" || browsLocale != "ja" ){
        allDelete = "All Delete";
      }else{
        allDelete = "全て消す";
      }

      const ignoreTargetArray = ["search_ngram_"];
      let headerSearchIncludeCheck = ignoreTargetArray.some((param)=>{
        return key.includes(param);
      })
      if(headerSearchIncludeCheck){
        //ヘッダーの検索項目に対してはタグを作らない
        noCreateBadgeFlg = true;
      }
      if(!headerSearchIncludeCheck){
        // for属性がq_classification_in_1のような要素の場合
        labelEl = document.querySelector(`label[for="q_${key}"]`);
      }
      
      if(labelEl){
        labelText = labelEl.textContent;
      }

      //タグに設定する名前が重複する場合
      if(labelText && checkDuplicateString.includes(labelText)){
        duplicateTagFlg = true;
      }
      checkDuplicateString += labelText;
      
      //表示する内容もなく、ヘッダーの絞り込み内容しか含まれていない場合
      if(!labelText && noCreateBadgeFlg){
        noCreateBadgeFieldFlg = true;
      }else if(!labelText || duplicateTagFlg){
        //ヘッダーの絞り込み要素はなくても、タグに表示する内容が空の場合はタグを作らない
        //重複するタグの内容が含まれる場合もタグを作らない
      }else{
        badges += `<li class="select-tag__item" data-ransack-param="q_${key}" data-action = "click->supplierSearch#removeBadge" style = "cursor: pointer;">${labelText}</li>`;
      }
    });
    badges += `</ul></div><div class="select-tag__delete" id = "delete_all_badges" style = "cursor: pointer;">${allDelete}</div>`;
    
    if(noCreateBadgeFieldFlg || !badges.includes('<li')){
      badges = "";
    }

    return badges;
  }

  //検索条件を表示するバッジ削除時のロジック
  removeBadge(e){
    //削除対象のクエリパラメータの元となる値を取得
    let ransackParam = e.target.dataset.ransackParam;
    //先にフォームの内容を更新してから、更新されたフォームの内容でリクエスト送信やタグ作成を行う
    let inputElement = document.getElementById(`${ransackParam}`);

    //クリックしたバッジに対応するチェックボックスのチェック削除
    if(inputElement){
      //左ナビのチェックボックスのチェックは、input要素の前の兄弟要素のlabel要素のafter擬似要素で付けられていて、
      //checkedの有無でopacityの0,1が切り替わるようにスタイリングされている
      inputElement.checked = false;
    }

    //フォーム取得
    let newForm = document.getElementById("supplier_form");
    //フォームの送信データを取得
    let formData = new FormData(newForm);
    //クエリ文字列を構築
    let queryString = new URLSearchParams(formData).toString();

    //別のフォームでのアクション実行時、もしくは前のアクション実行時のクエリパラメータが存在していればくっつける
    if(window.formSharedParams){
      queryString += "&";
      queryString += window.formSharedParams;
    }
    window.formSharedParams = queryString;

    let ransack_trim_pattern = /_(\d+)$/;
    let match = ransackParam.match(ransack_trim_pattern);
    let ransackParamsValue;
    if (match) {
      ransackParamsValue = match[1];
    }
    let newRansackParams = ransackParam.replace("q_", "");

    //デコードしたクエリパラメータ文字列にマッチさせるパターン作成
    let pattern = "q\\[";
    pattern += newRansackParams;
    pattern += "\\]\\[\\]=";
    pattern += ransackParamsValue;
    pattern += "&";
    //クエリ文字列から削除対象のパターンを削除
    let updatedQueryString = decodeURIComponent(queryString).replace(new RegExp(pattern), '');
    //成形後のクエリ文字列を引数に新しいバッジを作成
    let ulElement = this.genQueryBadgeEl(updatedQueryString);
    //リクエスト用URL生成
    let url = newForm.action + `?${updatedQueryString}`;
    // 更新されたURLを取得
    let updatedUrl = url.toString();

    //タグに対応するパラメータ削除後のURLでリクエスト
    fetch(updatedUrl, {
      'X-CSRF-Token': this.token,
      method: "GET"
    })
    .then(response => response.text())
    .then(html =>{
      let parser = new DOMParser();
      //更新したい箇所のみを取得
      let frame = document.getElementById('product__wrap');
      //レスポンスのHTMLをパース
      let doc = parser.parseFromString(html, 'text/html');
      //更新したい箇所のみを取得
      let newFrame = doc.getElementById('product__wrap');
      //前の要素を削除
      frame.innerHTML = '';
      //新しい要素を挿入
      //挿入する要素が見つからなかった時エラーにならないように条件分岐
      if(newFrame){
        frame.insertAdjacentHTML('afterbegin', newFrame.outerHTML);
      }else{
        frame.insertAdjacentHTML('afterbegin', '');
      }

      //検索内容を表示するバッジを挿入(タグ削除後の検索内応で作られたタグになっている)
      let parentUl = document.getElementById("serach_lists");
      parentUl.innerHTML = ulElement;

      //全て消すイベント登録
      let badgeDeleteAllElement = document.getElementById('delete_all_badges');
      if(badgeDeleteAllElement){
        badgeDeleteAllElement.addEventListener('click', this.redirectCurrentURL);
      }
    })
  }
}