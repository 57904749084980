$(function() {
  // 郵便番号から住所を設定する
  var Postcode = {
    init: function(id) {
      let $zipcode = $('#' + id);
      $zipcode.on('input', this.onInputZipcode.bind(this));

      this.url = $zipcode.data('url');
      this.prefectureId = $zipcode.data('prefecture');
      this.cityId = $zipcode.data('city');
    },

    onInputZipcode: function(e) {
      let v = $(e.target).val();
      if (v.length != 7) {
        return;
      }

      $.ajax({
        url: this.url,
        type: 'get',
        data: {zip: v},
        success: function(postcodes) {
          if (postcodes.length > 0) {
            this.setAddress(postcodes);
          }
        }.bind(this),
      });
    },

    setAddress: function(postcodes) {
      let postcode = postcodes[0];
      $('#' + this.prefectureId + ' option').each(function() {
        if ($(this).text().indexOf(postcode.prefecture) != -1) {
          $(this).parent().val($(this).val());
        }
      });
      var town = postcode.town || '';
      $('#' + this.cityId).val(postcode.city + town);
    },
  };

  $('.zip-code').each(function(i, o) {
    Object.create(Postcode).init($(o).attr('id'));
  });
});