// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
// import "channels"
// import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all'
// import '../stylesheets/buyer_bootstrap';
import '../stylesheets/buyer';
import '../buyer/supplier_contracts-action'
import '../buyer/processor_contracts-action'
import '../buyer/buyer_contracts'
import 'select2/dist/css/select2'
import '../buyer/fabric_search'
import '../buyer/supplier_page_search'
import '../buyer/buyer_informations'
import '../buyer/suppliers'
import '../buyer/suppliers_show'
import '../buyer/fabrics'
import '../buyer/postcode'
import '../buyer/cart'
import '../supplier/supplier_contracts'
import '../buyer/ga-accept-cookie'
import '../stylesheets/ga-accept-cookie.css';
import { application } from "chat/application"

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

import SupplierSearchController from "../buyer/supplier_search_controller"
application.register("supplierSearch", SupplierSearchController)
