import { each } from "jquery";

$(function() {
  // body ページ指定
  if ($('#fabrics-index').length) {
    // ターゲットul指定
    if ($('.show_large_fabric')) {
      $("img[data-id]").on('click', function() {
        var target = $(this).closest('li');
        $('.active').hide();
        $('.active').prev().show();
        target.hide();
        target.next().show();
      });

      $("a[data-id]").on('click', function() {
        var target = $(this).closest('li');
        target.hide();
        target.prev().show();
      });
    }
  }
  // 生地ページ
  if ($('#fabrics-show')) {
    $(".thumbnail img").on("click", function () {
      var img = $(this).attr("src");
      $('.thumbnail li').removeClass('active');
      $(this).parent().addClass('active');
      $(".main img").fadeOut(500, function () {
        $(".main img").attr("src", img).on("load", function () {
          $(this).fadeIn(500);
        });
      });
    });
    if ($('.modal_on') && $("#js-popup")) {
        $(".modal_on").on('click', function () {
            $("#js-popup").show();
            $("#js-popup").addClass('is-show');
        });
    }
  }
});