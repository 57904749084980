import { each } from "jquery";

$(function() {
  if ($('#supplier_search')) {
    $('body').on('click', '#more-link' , function() {
      $('#more-link').hide();
      $('.icon_loader').show();
    });
  }
});
$(function () {
    $(".btn-reset").on("click", function () {
        clearForm(this.form);
    });

    function clearForm (form) {
        $(form).find("input[type='checkbox']")
          .prop("checked", false)
        $(form).find("input[type='search']")
          .val("");

    }
});
