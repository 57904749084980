import { each } from "jquery";

$(function () {
  if ($('#cart-index')) {
    let deletedFabricFlg = $('#link_btn-cart').data('deleted-cart-flg');
    let commitButton = $('input[name="commit"]');
    //削除された生地がカートの中にある場合、ラジオボタンのチェンジイベントで「依頼を確定する」ボタンが活性化しない
    if(!deletedFabricFlg){
      $('input[name="price_display"]').on('change', function() {
        if ( $(this).prop('checked') == true && $('input[name="appointment"]').prop('checked') == true) {
          commitButton.removeClass('disabled', 'disabled');
          commitButton.prop('disabled',false);
        }
      });
  
      $('input[name="appointment"]').on('change', function() {
        if ( $(this).prop('checked') == true && $('input[name="price_display"]').prop('checked') == true) {
          commitButton.removeClass('disabled', 'disabled');
          commitButton.prop('disabled',false);
        }
      });
    }
  }
});
