import { each } from "jquery";

$(function() {
  // body ページ指定
  if ($('#suppliers-show').length) {
    // ターゲットul指定
    if ($('.show_large_fabric')) {
      $("#newarrival img[data-id]").on('click', function() {
        var target = $(this).closest('li');
        $('.active').hide();
        $('.active').prev().show();
        target.hide();
        target.next().show();
      });

      $("a[data-id]").on('click', function() {
        var target = $(this).closest('li');
        target.hide();
        target.prev().show();
      });
    }
  }
});
