window.addEventListener('DOMContentLoaded', function () {

  'use strict';
  //localStorage から ga_cookie_opt_in の値を取得
  const cookieOptin = localStorage.getItem('ga_cookie_opt_in');
  //Enable Google Analytics（UA-XXXX-Y の部分は正しいものに書き換えてください）
  var google_disable = ga_configs.googleDisable;
  var alertHtml = ga_configs.alertHtml;

  //ga_cookie_opt_in = no なら Google Analytics を無効に
  if (cookieOptin == 'no') {
    window[google_disable] = true;

    //ga_cookie_opt_in = yes なら Google Analytics トラッキングコードを発行
  } else if (cookieOptin == 'yes') {
    window[google_disable] = false;
  } else {
    window[google_disable] = true;
    //通知の表示（テキストの内容やプライバシーポリシーへのリンクは必要に応じて変更してください）
    const accept = document.createElement('div');
    accept.setAttribute('class', 'module-ga-cookie-accept-bar');
    accept.setAttribute('id', 'name-ga-cookie-accept-bar');
    accept.innerHTML = alertHtml;
    document.body.appendChild(accept);
  }
  //各ボタンの取得
  const acceptBtn = document.getElementById('name-ga-cookie-accept-btn');

  //「同意する」ボタンのクリックでオプトイン（ga_cookie_opt_in = yes）
  if (acceptBtn) {
    acceptBtn.onclick = function () {
      localStorage.setItem('ga_cookie_opt_in', 'yes');
      document.getElementById('name-ga-cookie-accept-bar').classList.add('state-remove');
      //CSS Animation が完了するまで待機するための力技……
      window.setTimeout('window.location.reload(false)', 500);
    };
  }
});