$(function(){
  if ($('#buyer_informations-edit')[0]) {
    $('.clear').on('click' ,function(){
      // input file をクリア
      $(this).parent('div').parent().children('input').val('');
      // cache削除
      $(this).parent('div').parent().prev('dt').children('input[name*="_cache"]').val('');
      // サムネイル削除
      $(this).parent('div').next('.media').html('');
    });
  }
});
