import { each } from "jquery";

$(function() {
  // body ページ指定
  if ($('#suppliers-index') || $('#suppliers-show')) {
    // ターゲットul指定
    if ($('.modal_on') && $("#js-popup")) {
      $(".modal_on").on('click', function() {
        $("#js-popup").show();
        $("#js-popup").addClass('is-show');
      });
    }
    if ($('.modal_on_show_all') && $("#js-popup2")) {
      $(".modal_on_show_all").on('click', function() {
        $("#js-popup2").show();
        $("#js-popup2").addClass('is-show');
      });
      $('#js-close-btn2').on('click',function(){
        $('#js-popup2').fadeOut(200);
        return false;
      });
    }
  }
});
