import { each } from "jquery";

$(function(){
  if($('#new_processor_contract')){
    $('input[name="processor_contract[plan_id]"]').on('click' ,function(){
      $('.optionttl').find(':checkbox').prop("checked", false);
      $('.plan_options_area').hide();
      var planId = $(this).val();
      $('.plan_options_area--' + planId).show();
    });

    $('input[name="agreement"]').on('change', function() {
      if ( $(this).prop('checked') == false ) {
        $('input[name="commit"]').attr('disabled', 'disabled');
      } else {
        $('input[name="commit"]').removeAttr('disabled');
      }
    });

    $('input[name="processor_contract[plan_option_ids][]"]').on('click', function() {
      var $box = $(this);
      var goupCode = $box.data('group-code');
      if(!goupCode) return true;
      if ($box.is(":checked")) {
        var group = 'input:checkbox[data-group-code="' + goupCode + '"]';
        $(group).prop("checked", false);
        $box.prop("checked", true);
      } else {
        $box.prop("checked", false);
      }
    });
  }

});